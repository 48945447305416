import React, { useState } from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Bold = ({ children }) => <p className="font-bold">{children}</p>;
const Text = ({ children }) => <p className="">{children}</p>;

const options = (color) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.HYPERLINK]: (node, children) => {
      const [hover, setHover] = useState(false);
      return (
        <a
          className=""
          target="_blank"

          href={node.data.uri}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {children}
        </a>
      );
    },
  },
  // renderText: text => text.replace('!', '?'),
});

export default ({ doc, color }) =>
  documentToReactComponents(doc, options(color));
